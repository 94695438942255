// Tenant borders
$tenant-payment-link-radius: 0px;
$tenant-buttom-radius: 0px;
$tenant-dropdown-radius: 0px;
$tenant-chart-table-row-radius: 0px;
$tenant-action-create-item-radius: 0px;
$tenant-modal-content-radius: 0px;
$tenant-config-modal-cardItem-radius: 0px;
$tenant-select-radius: 0px;
$tenant-input-radius: 0px;

// Tenant colors
$tenant-white: #e6f1f7 !default;
$tenant-black: #333333 !default;
$tenant-primary-color: #001641 !default;
$tenant-seconday-color: #7664ff !default;
$tenant-terciary-color: #001641 !default;
$tenant-date-filter-background-color: #efedff !default;
$tenant-background-color-primary: #ffffff;
$tenant-nav-link-color-default: #999999 !default;
$tenant-nav-link-hover-color: #001641 !default;
$login-background-color: $tenant-background-color-primary !default;
$login-font-color: $tenant-black !default;
